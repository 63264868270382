import React from "react"
import PropTypes from "prop-types"

import { CoverContent } from "components/anti/cover/cover"
import { Link } from "components/anti/link/link"

export const Download = ({
  background,
  className,
  title,
  titleClassName,
  text,
  textClassName,
  buttons,
  buttonsClassName,
  image,
  imageAlt,
}) => {
  return (
    <CoverContent
      theme="dark"
      img={background}
      contentMaxWidth="mw-100"
      className={`download ${className}`}
    >
      <div className="row">
        <div className="col-text col-md-6 col-lg-7">
          {title && <h2 className={`${titleClassName}`}>{title}</h2>}
          {text && <p className={textClassName}>{text}</p>}
          <div className={`${buttonsClassName}`}>
            {buttons.map((button, i) => {
              return (
                // <a href={button.url} key={i}>
                <div key={i}>
                  <img
                    src={button.icon.sourceUrl}
                    className="img-fluid mx-2"
                    alt={button.alt || "Download"}
                  />
                </div>
                // </a>
              )
            })}
          </div>
        </div>
        <div className="col-img order-md-first col-md-6 col-lg-5">
          {image && <img src={image} className="img-fluid" alt={imageAlt} />}
        </div>
      </div>
    </CoverContent>
  )
}

Download.propTypes = {
  background: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  buttons: PropTypes.any,
  buttonsClassName: PropTypes.any,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
}

Download.defaultProps = {
  className: "",
  titleClassName: "",
  textClassName: "",
  buttonsClassName: "",
  imageAlt: "Download",
}
