import React from "react"
import Marquee from "react-fast-marquee"

import { useScrollAnim } from "src/components/hooks/hooks"

const HomePartnership = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="py-main sc-home-partnership" ref={trigger}>
      <div className="container">
        <div className="row row-0">
          <div className="col-md-8">
            <h2 className={anim(1)}>{data?.title}</h2>
            <p className={anim(2)}>{data?.text}</p>
          </div>
        </div>
      </div>
      <Marquee className="scroll-partner pt-4">
        {[...data?.partner, ...data?.partner].map((item, i) => (
          <img
            src={item?.logo?.mediaItemUrl}
            alt="img"
            className={`img-fluid ${anim(3 + i)}`}
            key={i}
          />
        ))}
      </Marquee>
    </section>
  )
}

export default HomePartnership
