import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Download } from "components/anti/download/download"

export const HomeDownload = ({ data }) => {
  const block = data
  const [trigger, anim] = useScrollAnim()

  return (
    <section ref={trigger} className="sc-home-download">
      <Download
        background={block.background.sourceUrl}
        title={block.title}
        titleClassName={anim(1)}
        text={block.text}
        textClassName={`mw-md-500px ${anim(2)}`}
        buttons={block.buttons}
        buttonsClassName={`${anim(3)} d-flex`}
        image={block.image.sourceUrl}
      />
    </section>
  )
}
