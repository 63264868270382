import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Button } from "../../anti/buttons/buttons"

import floatGfx from "../../../assets/img/floating-3.svg"
import imgCover from "src/assets/img/home/img_home-bg1.jpg"
import imgLogo from "src/assets/img/home/img_home-logo.svg"

export const HomeSaveIbuku = ({ reverse }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="py-main sc-home-benefits" ref={trigger}>
      <div className="container">
        <div className="row row-5">
          <div className={`col-lg-6 col-text ${reverse && "order-lg-last"}`}>
            <img src={imgLogo} alt="Dompet Aman" className="img-fluid mb-3" />
            <h2 className={`h1 ${anim(1)}`}>
              Dapatkan Akses Global dan Penawaran Terbaik
            </h2>
            <p className={`${anim(2)}`}>
              Rasakan kemudahan dan kenyamanan untuk mencapai impianmu dengan
              loyalty poin dan program membership yang inovatif.
            </p>
            <Button variant="link" className={`${anim(3)} mb-3`} link="/about">
              About Us
            </Button>
          </div>
          <div className={`col-lg-6 col-image ${reverse && "order-lg-first"}`}>
            <img
              src={imgCover}
              className={`${anim(5)} img-benefits img-fluid `}
              alt={"Save Ibuku"}
            />
            <img
              src={floatGfx}
              className={`${anim(6)} gfx-wrapper gfx-alt`}
              alt="gfx"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
