import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"
import { Newsletter } from "components/anti/newsletter/newsletter"

export const HomeNewsletter = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="py-main sc-home-newsletter" ref={trigger}>
      <div className="container">
        <h2 className={`h1 ${anim(1)}`}>{data.title}</h2>
        <div className="row row-3">
          <div className="col-md-6">
            <p className={`${anim(2)}`}>
              {data.text}
            </p>
          </div>
          <div className={`${anim(3)} col-md-6`}>
            <Newsletter
              floatingLabel
              variant="underline"
              label="Alamat email"
              className="mt-sm-down-3"
              btnName="Submit"
              successMsg="Terimakasih telah mendaftar"
              failedMsg="Email tidak valid"
              existMsg="Email sudah terdaftar"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
