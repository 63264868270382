import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "components/seo"
import Layout from "components/layout"

//load components
import { HomeCover } from "components/pages/home/cover"
import { HomeCoverNew } from "./cover-new"
import { Features } from "components/global/features/features"
import { HomeServices } from "components/pages/home/services"
import { HomeBenefits } from "components/pages/home/benefits"
import { HomeDownload } from "components/pages/home/download"
import { HomeNewsletter } from "components/pages/home/newsletter"
import { HomeUpdate } from "components/pages/home/update"
import { HomeWfb } from "components/pages/home/wfb"
import { HomeSaveIbuku } from "./save-ibuku"
import HomePartnership from "./partnership"

const Home = ({ pageContext, path }) => {
  const lang = pageContext.langKey
  const data = useStaticQuery(GET_HOME_DETAILS)
  const blocks = data?.wordPress?.page?.homePage
  const servicesList = data?.wordPress?.services?.nodes
  const updateList = data?.wordPress?.categories?.nodes
  const updateFeatured = data?.wordPress?.tags?.nodes[0]

  return (
    <Layout lang={lang} path={path}>
      <Seo title="Beranda" />
      <HomeCover data={blocks.cover} />
      {/* <HomeBenefits data={blocks.benefits2} /> */}
      {/* <HomeCoverNew /> */}
      <HomeSaveIbuku />
      <Features data={blocks.overview} />
      <HomeServices data={blocks.services} servicesList={servicesList} />
      {/* <HomeBenefits data={blocks.benefits} reverse /> */}
      {/* <HomeWfb data={blocks.workFromBali} /> */}
      {/* <HomeUpdate data={updateList} featured={updateFeatured} /> */}
      <HomePartnership data={blocks.partnership} />
      <HomeDownload data={blocks.download} />
      <HomeNewsletter data={blocks.newsletter} />
    </Layout>
  )
}

export default Home

const GET_HOME_DETAILS = graphql`
  query HomePage {
    wordPress {
      page(id: "home", idType: URI) {
        homePage {
          cover {
            sliderContent {
              text
              title
              button {
                title
                url
              }
            }
            image {
              sourceUrl
            }
            imageMobile {
              sourceUrl
            }
            footer {
              text
              logo {
                sourceUrl
              }
            }
            buttons {
              text
              url
            }
          }
          benefits {
            buttons {
              text
              url
            }
            image {
              sourceUrl
            }
            list {
              text
            }
            text
            title
          }
          benefits2 {
            buttons {
              text
              url
            }
            image {
              sourceUrl
            }
            list {
              text
            }
            text
            title
          }
          workFromBali {
            title
            text
            price {
              text
              prevPrice
              perDays
              fieldGroupName
              currentPrice
              button {
                url
                text
              }
            }
            logo {
              sourceUrl
            }
            image {
              sourceUrl
            }
            asset {
              sourceUrl
            }
          }
          partnership {
            title
            text
            partner {
              logo {
                mediaItemUrl
              }
            }
          }
          download {
            title
            text
            background {
              sourceUrl
            }
            image {
              sourceUrl
            }
            buttons {
              url
              icon {
                sourceUrl
              }
            }
          }
          newsletter {
            title
            text
          }
          overview {
            label
            features {
              downloadButton
              image {
                sourceUrl
              }
              buttons {
                text
                url
              }
              text
            }
          }
          services {
            titleline1
            titleline2
            text
          }
        }
      }
      services {
        nodes {
          slug
          service {
            cover {
              title
              image {
                sourceUrl
              }
              imagemobile {
                sourceUrl
              }
              logo {
                sourceUrl
              }
            }
          }
        }
      }
      categories(where: { orderby: DESCRIPTION }) {
        nodes {
          name
          slug
          posts {
            nodes {
              title
              date
              slug
            }
          }
        }
      }
      tags {
        nodes {
          name
          slug
          posts {
            nodes {
              date
              featuredImage {
                node {
                  sourceUrl
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              slug
              title
            }
          }
        }
      }
    }
  }
`
