import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Slider } from "../../anti/slider/slider"
import { Card } from "../../anti/card/card"
import { Button } from "../../anti/buttons/buttons"

export const HomeServices = ({ data, servicesList }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="pt-main sc-home-services" ref={trigger}>
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-7">
            <h2 className={`h1 ${anim(1)}`}>
              {data.titleline1} <br /> {data.titleline2}
            </h2>
          </div>
          <div className="col-md-5 d-flex align-items-center">
            <p className={`mb-0 ${anim(2)}`}>{data.text}</p>
          </div>
        </div>
        {/* <div className="heading">
          <h2 className={`h1 ${anim(1)}`}>
            <strong>{data.titleline1} </strong>
            {data.titleline2}
          </h2>
          <p className={`${anim(2)}`}>{data.text}</p>
        </div> */}
      </div>

      <Slider
        visibleInitial={true}
        visibleXxlDown={true}
        visibleXlDown={true}
        showInitial={4}
        showXxlDown={4}
        showXlDown={4}
        arrowsInitial={false}
        arrowsXxlDown={false}
        arrowsXlDown={false}
        className={`slick-px-0 d-none d-lg-block home-services-list`}
      >
        {servicesList.map((item, i) => {
          const detail = item.service.cover
          return (
            <div key={i}>
              <Card
                variant="overlay"
                img={detail.image.sourceUrl}
                imgRatio="r-1-2"
                className={`${anim(
                  3 + i
                )} card-home-service hover-zoomIn position-relative`}
                link={`/services/${item.slug}`}
              >
                <img
                  src={detail.logo.sourceUrl}
                  className={`${anim(4 + i)} img-fluid`}
                  alt={detail.title}
                />
                <h3 className={`h2 ${anim(5 + i)}`}>{detail.title}</h3>
                <div className={`circle-service-${i}`}></div>
              </Card>
            </div>
          )
        })}
      </Slider>
      <div className="d-block d-lg-none home-services-list">
        <div className="row row-0">
          {servicesList.map((item, i) => {
            const detail = item.service.cover
            return (
              <div className={`col-md-6`} key={i}>
                <Card
                  variant="overlay"
                  img={detail.imagemobile.sourceUrl}
                  imgRatio={`r-2-1 ${i === 0 && "r-md-3-1"}`}
                  className={`${anim(3 + i)} card-home-service hover-zoomIn`}
                  link={`/services/${item.slug}`}
                >
                  <img
                    src={detail.logo.sourceUrl}
                    className={`${anim(4 + i)} img-fluid`}
                    alt="img"
                  />
                  <h3 className={`h2 ${anim(5 + i)}`}>{detail.title}</h3>
                  <span className={`${anim(6 + i)}`}>Lihat Detail</span>
                </Card>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
