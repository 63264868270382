import React, { useContext, useEffect } from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Cover } from "components/anti/cover/cover"
import { Button } from "components/anti/buttons/buttons"
import { Slider } from "components/anti/slider/slider"
import { LoadingContext } from "src/context/loading-context"

import floating3 from "assets/img/floating-1.svg"

export const HomeCover = ({ data }) => {
  const { initialLoading } = useContext(LoadingContext)
  const [trigger, anim] = useScrollAnim()

  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  console.log("data", data)
  return (
    <>
      <section className="sc-home-cover h-vh-100 cover-full" ref={trigger}>
        {!initialLoading && (
          <Cover
            variant="basic"
            theme="dark"
            imgMd={data.image.sourceUrl}
            img={data.imageMobile.sourceUrl}
            imgHeight="home-image"
            // title={data.title}
            // text={data.text}/.//asda.asd
            className="cover-home animated fadeInUp"
            titleClassName={`${anim(1)}`}
            textClassName={`${anim(2)}`}
          >
            <Slider
              visibleInitial={false}
              visibleXxlDown={false}
              visibleXlDown={false}
              visibleLgDown={false}
              visibleMdDown={false}
              visibleSmDown={false}
              arrowsInitial={false}
              arrowsXxlDown={false}
              arrowsXlDown={false}
              arrowsLgDown={false}
              arrowsMdDown={false}
              arrowsSmDown={false}
              showInitial={1}
              showXxlDown={1}
              showXlDown={1}
              showLgDown={1}
              showMdDown={1}
              showSmDown={1}
              dotsInitial
              dotsXxlDown
              dotsXlDown
              dotsLgDown
              dotsMdDown
              dotsSmDown
              fade
              autoplay
              infinite
              autoplaySpeed={5000}
              className="slider-home"
            >
              {data?.sliderContent.map((item, i) => (
                <div key={i}>
                  <h1 class="cover-title scroll-fadeInUp fadeInUp delayp1">
                    {item?.title}
                  </h1>
                  <div class="cover-text scroll-fadeInUp fadeInUp delayp2">
                    {item?.text}
                  </div>
                  {item?.button?.title && (
                    <Button
                      variant="link"
                      className={`${anim(3)} mt-3`}
                      link={item?.button?.url}
                      key={i}
                    >
                      {item?.button?.title}
                    </Button>
                  )}
                </div>
              ))}
            </Slider>
            {/* {data.buttons.map((button, i) => (
              <Button
                variant="link"
                className={`${anim(3)} mt-3`}
                link={button.url}
                key={i}
              >
                {button.text}
              </Button>
            ))} */}
            {/* <div className="cover-footer">
              <p className={`${anim(4)}`}>{data.footer.text}</p>
              <img
                src={data.footer.logo.sourceUrl}
                className={`${anim(5)} img-fluid`}
                alt="Logo BPJS"
              />
            </div> */}
            <div className="scroll-bottom">
              <p className={`${anim(5)}`}>Scroll</p>
              <div className="scroll-circle" />
            </div>
            <img
              src={floating3}
              className={`${anim(6)} gfx-1 d-md-block d-none`}
            />
          </Cover>
        )}
      </section>
    </>
  )
}
